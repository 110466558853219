.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.container {
  margin-left: 15px;
  margin-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border: 2px solid lightgrey;
}

.logos {
  padding: 15px;
}

  .displaynone {
    display: none;
  }

  .mybtn {
    margin-right: 15px;
  }

  .collapseBtns {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .invoice-box {
    background-color: rgb(255, 215, 141);
    padding-left: 30px;
    margin-left: -15px;
    padding-right: 30px;
    margin-right: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .delivery-box {
    background-color: rgb(141, 255, 166);
    padding-left: 30px;
    margin-left: -15px;
    padding-right: 30px;
    margin-right: -15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  #info-btn {
    color: blue;
    cursor: pointer;
  }

  .fup-container {
    width: 100px;
  }

  .fup-title {
    height: 100px;
    object-fit: cover;
  }

  .pointer {
    cursor: pointer;
  }

  .fup-card-text {
    overflow: hidden;
    font-family: monospace;
    font-size: 0.8rem;
    padding: 0px;
    text-overflow: ellipsis;
    white-space: nowrap;

  }

  .fup-card {
    max-height: 200px;
    margin-bottom: 15px;
    /* min-width:150px; */
  }

  .fup-body {
    padding: 5px;
    padding-top: 0px;
  }

  .indicator {
    height: 1rem;
    width: 1rem;
  }

  .red-text {
    color: red;
    margin-left: 15px;
    
  }